import React from "react"

export default function SmallCard({ image, primary, secondary }) {
  return (
    <div className="flex flex-col">
      <div className="mb-2 pb-3/5 relative">
        <div className="absolute h-full w-full overflow-hidden">{image}</div>
      </div>
      <div className="tiny-title">
        <p>{primary}</p>
        <p>{secondary}</p>
      </div>
    </div>
  )
}
