import { Link } from "gatsby"
import React from "react"

export default function MobileMenu({ menuOpen, setMenuOpen, details }) {
  const projectLinks = [
    "hospitality",
    "residential",
    "multi-residential",
    "commercial",
  ]
  const links = [
    {
      name: "About Us",
      link: "/about-us",
      internal: true,
    },
    {
      name: "DACCA Group",
      link: "/dacca-group",
      internal: false,
    },
    // {
    //   name: "Contact",
    //   link: "/contact",
    //   internal: true,
    // },
    // {
    //   name: "Instagram",
    //   link: details.insta,
    //   internal: false,
    // },
    // {
    //   name: "Press",
    //   link: details.group,
    //   internal: false,
    // },
    // {
    //   name: "Partners",
    //   link: details.group,
    //   internal: false,
    // },
  ]

  return (
    <div className="absolute top-0 right-0">
      <div
        className={
          "fixed flex flex-col sm:flex-row text-center sm:text-left sm:space-x-8 items-center justify-center bg-neutral-100/80 h-screen z-40 duration-300 transition-all px-8 py-[10vh] sm:pt-[20vh] " +
          (menuOpen
            ? "opacity-100 pointer-events-auto -translate-x-full"
            : "opacity-0 pointer-events-none translate-x-0")
        }
      >
        <ul className="z-40 flex flex-col justify-center space-y-4 sm:space-y-2 h-full mb-8 sm:mb-0">
          {projectLinks.map(project => (
            <li>
              <Link
                to={"/" + project}
                className="uppercase whitespace-nowrap text-xs tracking-200"
              >
                {project}
              </Link>
            </li>
          ))}
        </ul>
        <ul className="z-40 flex flex-col justify-center space-y-4 sm:space-y-2 h-full">
          {links.map(link => (
            <>
              {link.internal ? (
                <li>
                  <Link
                    to={link.link}
                    className="uppercase whitespace-nowrap text-xs tracking-200"
                  >
                    {link.name}
                  </Link>
                </li>
              ) : (
                <li>
                  <a
                    href={link.link}
                    className="uppercase whitespace-nowrap text-xs tracking-200"
                  >
                    {link.name}
                  </a>
                </li>
              )}
            </>
          ))}
        </ul>
      </div>
    </div>
  )
}
