import React from "react"

export default function PlayfairTitle(props) {
  return (
    <>
      <h2 className="text-3xl font-serif tracking-90 text-center mb-5 uppercase">
        {props.title}
      </h2>
    </>
  )
}
