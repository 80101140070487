import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SmallCard from "./SmallCard"

export default function Dropdown({ to, heading, items }) {
  if (to === null) {
    to = slugify({ heading })
  }

  return (
    <div className="group">
      <Link to={to}>{heading}</Link>
      {items.length > 0 && (
        <div className="absolute left-0 right-0 max-w-4xl mx-auto bg-white overflow-hidden max-h-0 grid grid-rows-1 grid-cols-4 grid-flow-col gap-2 px-2 w-full transition-all duration-500 items-center opacity-0 group-hover:opacity-100 group-hover:max-h-40 z-40">
          {items.map(item => (
            <Link to={`/projects/${item.slug}`} className="h-full py-2">
              <SmallCard
                image={
                  <GatsbyImage
                    image={getImage(item.cover.image.localFile)}
                    alt={
                      item.cover.alternativeText ||
                      item.cover.image.alternativeText
                    }
                    className="object-cover h-full w-full transition-transform duration-500 hover:scale-105"
                  />
                }
                primary={item.title}
              />
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "")
  str = str.toLowerCase()

  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")

  return str
}
