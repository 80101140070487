import React from "react"

import Navbar from "./Navbar"
import Footer from "./Footer"
import Hero from "./Hero"
import { useState } from "react"
import Seo from "./Seo"

export default function Layout(props) {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      {props.Seo ? (
        <Seo
          title={props.Seo.title}
          description={props.Seo.description}
          image={props.Seo.image}
          remoteImage={props.Seo.remoteImage}
          article={props.Seo.article}
        />
      ) : null}
      <div className="text-gray-500">
        <div
          className={
            props.noHero
              ? "flex flex-col"
              : "flex flex-col sm:h-screen sm:max-h-screen"
          }
        >
          {props.mainPage ? (
            <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} mainPage />
          ) : (
            <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          )}
          {props.noHero ? (
            <></>
          ) : (
            <Hero
              image={props.heroImage}
              main={props.heroMain}
              support={props.heroSupport}
              mainPage={props.mainPage}
            />
          )}
        </div>
        <div id="Content">{props.content}</div>
        <Footer />
      </div>
    </>
  )
}
