import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ProjectDropdown from "./ProjectDropdown"
import MobileMenu from "./MobileMenu"

export default function Navbar({ menuOpen, setMenuOpen, mainPage }) {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiProjects {
        nodes {
          sector {
            slug
          }
          title
          slug
          cover {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      strapiDetails {
        insta: daccaArchitectureInstagramLink
        group: daccaGroupSiteLink
      }
    }
  `)

  const details = data.strapiDetails
  const projects = data.allStrapiProjects.nodes

  if (mainPage)
    return (
      <nav className="h-[6vh] sm:h-[10vh] lg:h-[6vh] flex fex-col justify-center items-center">
        <div className="hidden lg:displ w-full px-4 lg:flex flex-row justify-between">
          <Link
            to="/about-us"
            className="mx-6 uppercase text-2xs tracking-200 py-4 transition-colors duration-300 hover:text-black"
          >
            About Us
          </Link>
          <div className="flex flex-row justify-between">
            <ProjectDropdown
              projects={projects}
              title="hospitality"
              to="/hospitality"
            />
            <ProjectDropdown
              projects={projects}
              title="residential"
              to="/residential"
            />
            <ProjectDropdown
              projects={projects}
              title="multi-residential"
              to="/multi-residential"
            />
            <ProjectDropdown
              projects={projects}
              title="commercial"
              to="/commercial"
            />
          </div>
          {/* <a
            href={details.group}
            className="mx-6 uppercase text-2xs tracking-200 py-4 transition-colors duration-300 hover:text-black"
          >
            Dacca Group
          </a> */}
          <Link
            to="/dacca-group"
            className="mx-6 uppercase text-2xs tracking-200 py-4 transition-colors duration-300 hover:text-black"
          >
            DACCA Group
          </Link>
        </div>
        <div
          className="burger-menu z-50 lg:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
          role="button"
        >
          <div className={menuOpen ? "origin-left rotate-45" : ""} />
          <div
            className={
              menuOpen ? "opacity-0 translate-x-2" : "opacity-100 translate-x-0"
            }
          />
          <div className={menuOpen ? "origin-left -rotate-45" : ""} />
        </div>
        <MobileMenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          details={details}
        />
      </nav>
    )
  else
    return (
      <nav className="sticky top-0 z-50 bg-white lg:relative flex flex-row h-[10vh] sm:h-15vh justify-between lg:justify-center font-medium">
        <div className="w-full px-4 sm:px-10 lg:px-4 lg:w-auto flex flex-row justify-between lg:justify-start items-center lg:items-end">
          <SectorLinkWrapper>
            <ProjectDropdown
              projects={projects}
              title="hospitality"
              to="/hospitality"
            />
            <ProjectDropdown
              projects={projects}
              title="residential"
              to="/residential"
            />
          </SectorLinkWrapper>
          <Link to="/" className="lg:mx-6 lg:mt-6 lg:pb-4">
            <StaticImage
              src="../images/logo-nopad.svg"
              alt="DACCA Architecture Logo"
              placeholder="blurred"
              // width={200}
              className="w-[100px] lg:w-[200px]"
            />
          </Link>
          <div
            className="burger-menu z-50 lg:hidden"
            onClick={() => setMenuOpen(!menuOpen)}
            role="button"
          >
            <div className={menuOpen ? "origin-left rotate-45" : ""} />
            <div
              className={
                menuOpen
                  ? "opacity-0 translate-x-2"
                  : "opacity-100 translate-x-0"
              }
            />
            <div className={menuOpen ? "origin-left -rotate-45" : ""} />
          </div>
          <MobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            details={details}
          />
          <SectorLinkWrapper>
            <ProjectDropdown
              projects={projects}
              title="multi-residential"
              to="/multi-residential"
            />
            <ProjectDropdown
              projects={projects}
              title="commercial"
              to="/commercial"
            />
          </SectorLinkWrapper>
        </div>
        <div className="hidden lg:block lg:absolute inset-0 pointer-events-none">
          <div className="absolute top-0 right-0 pointer-events-auto pt-2">
            {/* <Link to="/about-us" className="nav-text">
              About Us
            </Link> */}
            {/* <a href={details.group} className="nav-text">
              Dacca Group
            </a> */}
            <Link to="/dacca-group" className="nav-text">
              DACCA Group
            </Link>
            {/* <Link to="/contact" className="nav-text">
              Contact
            </Link> */}
          </div>
          <div className="absolute top-0 left-0 pointer-events-auto pt-2">
            <Link to="/about-us" className="nav-text">
              About Us
            </Link>
            {/* <a href={details.insta} className="nav-text">
              Instagram
            </a>
            <a href={details.group} className="nav-text">
              Press
            </a>
            <a href={details.group} className="nav-text">
              Partners
            </a> */}
            {/* <Link to="/concepts/queanbeyan-bowling-club" className="nav-text">
              Queanbeyan Bowling Club Landing Page (Temporary)
            </Link> */}
          </div>
        </div>
      </nav>
    )
}

function SectorLinkWrapper(props) {
  return (
    <div className="hidden lg:flex flex-row items-end">{props.children}</div>
  )
}
