import React from "react"

export default function Hero(props) {
  if (props.mainPage) {
    return (
      <section className="h-[94vh] sm:h-[90vh] lg:h-[94vh] flex flex-col bg-gray-200 overflow-hidden">
        <div className="relative w-full h-full lg:h-[79vh] flex flex-col justify-center items-center mx-auto">
          <div className="grid w-full h-full">
            {props.image}
            <div
              style={{
                gridArea: "1/1",
                position: "relative",
                placeItems: "center",
                display: "grid",
              }}
            >
              {props.main}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:h-15vh items-center py-3 lg:py-8 px-3 lg:px-16 bg-gray-200">
          <p className="font-medium text-xs lg:text-sm leading-5 text-center tracking-160 max-w-3xl">
            {props.support}
          </p>
        </div>
      </section>
    )
  } else
    return (
      <section className="sm:h-[85vh] flex flex-col justify-end bg-gray-200">
        <div className="relative h-full w-full flex flex-col justify-center items-center mx-auto">
          <div className="grid w-full h-full">
            {props.image}
            <div
              style={{
                gridArea: "1/1",
                position: "relative",
                placeItems: "center",
                display: "grid",
              }}
            >
              {props.main}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center py-3 sm:py-2 md:py-[5vh] lg:py-8 px-3 lg:px-16 bg-gray-200">
          <p className="font-medium text-xs lg:text-sm leading-5 text-center tracking-160 max-w-3xl">
            {props.support}
          </p>
        </div>
      </section>
    )
}

Hero.defaultProps = {
  support:
    "We are a boutique, integrated architecture and interior design studio based in Sydney. We work closely with commercial, residential and hospitality clients to create beautiful and sustainable spaces that can be enjoyed for decades.",
}
