import React from "react"

import MailOutlineIcon from "@material-ui/icons/MailOutline"
import InstagramIcon from "@material-ui/icons/Instagram"

import PlayfairTitle from "./PlayfairTitle"
import { graphql, useStaticQuery } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      strapiDetails {
        tonyEmail
        tonyNumber
        address
        insta: daccaArchitectureInstagramLink
      }
    }
  `)

  const details = data.strapiDetails

  return (
    <footer className="flex flex-col justify-center items-center text-2xs leading-4 tracking-120 my-10 md:my-20 px-8 md:px-16">
      {/* <div className="instagram-carousel-wrapper">
        <h2>Follow Us</h2>
        <div className="instagram-widget"></div>
      </div> */}
      <div className="flex flex-col max-w-3xl w-full">
        <PlayfairTitle title="Contact Us" />
        <div className="flex flex-col">
          <div className="mb-4">
            <p>Tony Maluccio</p>
            <p>Nominated Architect 9248 (Antonio Maluccio)</p>
          </div>
          <div className="mb-4">
            <p>{details.tonyEmail}</p>
            <p>{details.tonyNumber}</p>
            <p>{details.address}</p>
          </div>
          <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row justify-between items-baseline">
            <div className="space-x-3">
              <a href={"mailto:" + details.tonyEmail}>
                <MailOutlineIcon
                  fontSize="medium"
                  className="text-black/50 hover:text-black/100"
                />
              </a>
              <a href={details.insta} target="_blank" rel="noreferrer">
                <InstagramIcon
                  fontSize="medium"
                  className="text-black/50 hover:text-black/100"
                />
              </a>
            </div>
            <p>&copy; 2021 Dacca Group. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
