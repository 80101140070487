import React from "react"
import Dropdown from "./Dropdown"

export default function ProjectDropdown({ projects, title, to }) {
  const filteredProjects = projects.filter(
    project => project.sector.slug === title
  )
  const items = filteredProjects.slice(0, 4)

  return (
    <Dropdown
      to={to}
      heading={
        <p className="mx-6 uppercase text-2xs tracking-200 py-4 transition-colors duration-300 hover:text-black">
          {title}
        </p>
      }
      items={items}
    />
  )
}
